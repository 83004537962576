<template>
  <draggable
    class="v-treeview v-treeview--dense v-treeview-draggable"
    :class="themeClassName"
    ghost-class="ghost"
    :move="onMove"
    :group="group"
    :value="localValue"
    :name="name"
    @input="updateValue"
  >
    <treeview-node
      v-for="item in value"
      :key="item.OptID"
      :group="group"
      :value="item"
      :name="name"
      @input="updateItem"
      v-on:editNode="editNode"
      v-on:removeNode="removeNode"
    />
  </draggable>
</template>

<script>
import draggable from "vuedraggable";
import TreeviewNode from "./DraggableTreeviewNode.vue";

export default {
  components: {
    draggable,
    TreeviewNode,
  },
  props: {
    value: {
      type: Array,
      default: [],
    },
    group: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      localValue: [...this.value],
    };
  },
  computed: {
    themeClassName() {
      return this.$vuetify.theme.isDark ? "theme--dark" : "theme--light";
    },
    hasChildren() {
      return this.value.OptChild.length > 0 || false;
    },
    isDraggableMenu() {
      return this.name == "OptionsOrder";
    },
  },
  watch: {
    value(value) {
      this.localValue = [...value];

      if (this.isDraggableMenu) {
        this.localValue.forEach((obj, i) => {
          obj.OptOrder = i + 1;
          obj.OptParent = null;
        });
      }
    },
  },
  methods: {
    updateValue(value) {
      this.localValue = value;
      this.$emit("input", this.localValue);
    },
    updateItem(itemValue) {
      const index = this.localValue.findIndex(
        (v) => v.OptID === itemValue.OptID
      );

      this.$set(this.localValue, index, itemValue);
      this.$emit("input", this.localValue);
    },
    editNode(value) {
      this.$emit("editNode", value);
    },
    removeNode(value) {
      this.$emit("removeNode", value);
    },
    onMove(e) {
      return (
        this.isDraggableMenu ||
        e.relatedContext.component.$attrs.name == "OptionsOrder"
      );
    },
  },
};
</script>
<style scoped>
.v-treeview-draggable {
  min-height: 30px !important;
}
.draggable-border-dash {
  border: 2px dashed #c1c1c1;
}
.v-treeview--dense .v-treeview-node__root {
  min-height: 20px;
}
</style>
