<template>
  <div>
    <s-toolbar label="Opciones" />
    <v-card :loading="loading || isLoading">
      <v-card-text>
        <v-row>
          <v-col cols="12" class="s-col-form">
            <v-radio-group
              dense
              row
              @change="changeTypeOption"
              v-model="Option.TypeOption"
              :disabled="Option.OptID > 0"
              hide-details
              :mandatory="true"
            >
              <v-radio label="Menu" :value="1"></v-radio>
              <v-radio label="Opción" :value="2"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="s-col-form">
            <s-text ref="OptName" label="Nombre" v-model="Option.OptName" @keydown.enter="saveOption()" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="s-col-form">
            <s-text v-model="Option.OptCode" label="Código" cols="12" md="6" @keydown.enter="saveOption()" />
          </v-col>
          <v-col cols="4" xs="6" class="s-col-form">
            <s-text v-model="Option.OptIcon" label="Icono" @keydown.enter="saveOption()">
              <template slot="append">
                <v-icon color="green darken-3"> {{ Option.OptIcon }}</v-icon>
              </template>
            </s-text>
          </v-col>
          <v-col cols="2" class="d-none d-sm-flex text-center ">
            <v-btn color="blue " icon @click="openLink('https://fontawesome.com/icons?d=listing&m=free')" title="visualize iconos fontawesome">
              <v-icon>fab fa-font-awesome</v-icon>
            </v-btn>
            <v-btn
              color="grey darken-2"
              icon
              @click="openLink('http://code.meta-platform.com/assets/mdi/preview.html')"
              title="visualize iconos material desing"
            >
              <v-icon>fab fa-medium</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row v-show="Option.TypeOption == 2">
          <v-col cols="6" class="s-col-form">
            <v-data-table
              dense
              v-model="Option.SecOptionLevel"
              :headers="dtOptionLevel.headers"
              :items="dtOptionLevel.items"
              hide-default-footer
              class="elevation-1"
              item-key="TypeLevel"
              show-select
            ></v-data-table>
          </v-col>
          <v-col cols="6" class="s-col-form">
            <v-data-table
              dense
              v-model="Option.SecOptionTransaction"
              :headers="dtOptionTransaction.headers"
              :items="dtOptionTransaction.items"
              hide-default-footer
              item-key="TypeOperation"
              class="elevation-1"
              show-select
            ></v-data-table>
          </v-col>

          <v-col cols="12" class="mb-0 pb-0 mt-2 text-right">
            <v-btn right x-small color="default" text @click="openDialogOSP()"> <v-icon x-small left>fas fa-plus</v-icon> Nuevo permiso</v-btn>
          </v-col>
          <v-col cols="12" class="s-col-form">
            <v-dialog v-model="showDialogOSP" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ isEditOSP ? "Actualizar Permiso Especial" : "Registrar Permiso Especial" }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col class="s-col-form">
                        <s-text v-model="OptionSpecialPermission.OspPermission" label="Permiso" class="py-0 my-0" autofocus="" />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="s-col-form">
                        <s-text v-model="OptionSpecialPermission.OspDescription" label="Descripción" class="py-0 my-0" />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-btn color="default " small text @click="closeDialogOSP()">
                    Cerrar
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" dark depressed small @click="saveOSP()">{{ isEditOSP ? "Actualizar" : "Agregar" }} </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-data-table
              dense
              :headers="dtOptionSpecialPermission.headers"
              :items="Option.SecOptionSpecialPermission"
              hide-default-footer
              item-key="OspID"
              class="elevation-1"
            >
              <template slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editOSP(item)">
                  fas fa-pen
                </v-icon>
                <v-icon small class="mr-2" @click="deleteOSP(item)">
                  fas fa-minus-circle
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row>
            <v-col cols="6" class="s-col-form">
              <v-btn @click="clearOption()" small block> <v-icon x-small left>fas fa-undo-alt</v-icon> Limpiar</v-btn>
            </v-col>

            <v-col cols="6" class="s-col-form">
              <v-btn @click="saveOption()" small block color="primary "> <v-icon small left>fas fa-save</v-icon> Guardar </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import _sOption from "@/services/Security/OptionService.js";
import _sDefinition from "@/services/General/DefinitionService.js";

export default {
  props: {
    optionedit: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    //Option
    createOptionID: -1,
    createTypeOption: 1,
    Option: {},
    isLoading: false,
    //OptionSpecialPermission
    createOptionSpecialPermissionID: -1,
    OptionSpecialPermission: {},
    showDialogOSP: false,
    isEditOSP: false,
    dtOptionLevel: {
      headers: [
        {
          text: "Nivel",
          value: "DedDescription",
        },
      ],
      items: [],
    },
    dtOptionTransaction: {
      headers: [
        {
          text: "Transacción",
          value: "DedDescription",
        },
      ],
      items: [],
    },
    dtOptionSpecialPermission: {
      headers: [
        {
          text: "Codigo",
          value: "OspPermission",
        },
        { text: "Descripción", value: "OspDescription" },
        { text: "", value: "actions", sortable: false, align: "right" },
      ],
    },
  }),
  computed: {
    defaultOption() {
      return {
        OptID: this.createOptionID,
        TypeOption: this.createTypeOption,
        OptCode: "",
        OptName: "",
        OptIcon: "mdi mdi-link",
        OptOrder: 0,
        SecStatus: 1,
        OptParent: null,
        SecOptionChild: [],
        SecOptionSpecialPermission: [],
        SecOptionLevel: [],
        SecOptionTransaction: [],
      };
    },
    defaultOptionSpecialPermission() {
      return {
        OspID: this.createOptionSpecialPermissionID,
        OptID: this.Option.OptID,
        OspPermission: "",
        OspDescription: "",
      };
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    configurateDefinitionOption() {
      this.dtOptionLevel.items.forEach((e, i) => {
        e.OptID = this.Option.OptID;
        e.OlvID = -i;
      });
      this.dtOptionTransaction.items.forEach((e, i) => {
        e.OptID = this.Option.OptID;
        e.OttID = -i;
      });
      this.OptionSpecialPermission.OptID = this.Option.OptID;
    },
    initialize() {
      _sDefinition.definitiongroup(1038, 0, this.$fun.getUserID()).then((r) => {
        r.data.forEach((e) => {
          e.TypeLevel = e.DedValue;
        });
        this.dtOptionLevel.items = r.data;
      });
      _sDefinition.definitiongroup(1024, 0, this.$fun.getUserID()).then((r) => {
        r.data.forEach((e) => {
          e.TypeOperation = e.DedValue;
        });
        this.dtOptionTransaction.items = r.data;
      });

      this.clearOption();
    },
    changeTypeOption() {
      this.createTypeOption = this.Option.TypeOption;
      true || this.$refs.OptName.focus();
    },
    openDialogOSP() {
      this.showDialogOSP = true;
    },
    closeDialogOSP() {
      this.isEditOSP = false;
      this.showDialogOSP = false;
      this.$nextTick(() => {
        this.OptionSpecialPermission = Object.assign({}, this.defaultOptionSpecialPermission);
      });
    },
    editOSP(item) {
      this.OptionSpecialPermission = Object.assign({}, item);
      this.isEditOSP = true;
      this.showDialogOSP = true;
    },
    deleteOSP(item) {
      this.Option.SecOptionSpecialPermission.splice(item);
    },
    saveOSP() {
      if (this.isEditOSP) {
        Object.assign(
          this.Option.SecOptionSpecialPermission.find((x) => x.OspID == this.OptionSpecialPermission.OspID),
          this.OptionSpecialPermission
        );
      } else {
        if (this.Option.SecOptionSpecialPermission == undefined) this.Option.SecOptionSpecialPermission = [];
        this.Option.SecOptionSpecialPermission.push(this.OptionSpecialPermission);

        this.createOptionSpecialPermissionID--;
      }
      this.closeDialogOSP();
    },
    clearOption() {
      this.Option = Object.assign({}, this.defaultOption);

      this.OptionSpecialPermission = Object.assign({}, this.defaultOptionSpecialPermission);
      this.$refs.OptName.focus();
    },
    validateOption() {
      var isValid = false;
      var message = "";

      isValid = this.Option.OptName.length > 0;

      if (!isValid) message = "Ingrese Nombre de Opción";
      else {
        isValid = this.Option.OptCode.length > 0;
        if (!isValid) message = "Ingrese Codigo de Opción";
        else {
          isValid = this.Option.OptIcon.length > 0;
          if (!isValid) message = "Ingrese Icono de Opción";
        }
      }

      if (!isValid) this.$store.getters.alertTop(message, "warning");
      return isValid;
    },
    saveOption() {
      if (this.validateOption()) {
        this.isLoading = true;
        var isCreate = false;

        _sOption.save(this.Option,this.$fun.getUserID()).then((r) => {
          if (this.Option.OptID <= 0) {
            isCreate = true;
            this.createOptionID--;
          }

          this.$emit("saveOption", r.data, isCreate);
          this.Option = Object.assign({}, this.defaultOption);
          this.isLoading = false;
        });
      }
      this.$refs.OptName.focus();
    },
    openLink(link) {
      window.open(link);
    },
  },
  watch: {
    Option() {
      this.configurateDefinitionOption();
    },
    optionedit(value) {
      this.Option = value;
      this.createTypeOption = this.Option.TypeOption;
      this.$refs.OptName.focus();
    },
  },
};
</script>
