<template>
  <div>
    <s-toolbar label="Estructura de Menu" />
    <v-card :loading="loading">
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-card>
              <v-card-subtitle>Menú sin asignar</v-card-subtitle>
              <v-card-text style="height:80px" class="overflow-y-auto">
                <draggable-treeview
                  group="option"
                  name="ToAssingMenu"
                  v-model="ToAssingMenu"
                  :class="[
                    ToAssingMenu.length == 0 ? 'draggable-border-dash' : '',
                  ]"
                  @input="input"
                  v-on:editNode="editOption"
                  v-on:removeNode="removeOption"
                ></draggable-treeview>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card>
              <v-card-subtitle>Opción sin asignar</v-card-subtitle>
              <v-card-text style="height:80px" class="overflow-y-auto">
                <draggable-treeview
                  group="option"
                  name="ToAssingOption"
                  v-model="ToAssingOption"
                  @input="input"
                  v-on:editNode="editOption"
                  v-on:removeNode="removeOption"
                  :class="[
                    ToAssingOption.length == 0 ? 'draggable-border-dash' : '',
                  ]"
                ></draggable-treeview>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text class="mt-n5">
        <v-card>
          <v-row class="ma-0">
            <v-spacer></v-spacer>
            <v-col cols="6">
              <v-btn @click="saveMenu" color="primary" small block>
                <v-icon left small>fas fa-save</v-icon> Guardar</v-btn
              >
            </v-col>
          </v-row>

          <v-card-text>
            <draggable-treeview
              group="option"
              name="OptionsOrder"
              v-model="OptionsOrder"
              :class="[OptionsOrder.length == 0 ? 'draggable-border-dash' : '']"
              @editNode="editOption"
              @input="inputMenu"
            ></draggable-treeview>
          </v-card-text>
        </v-card>
      </v-card-text>
      <!-- <v-col cols="3">
      <pre class="caption">  {{ JSON.stringify(OptionsOrder, null, 2) }}</pre>
    </v-col> -->
    </v-card>
  </div>
</template>
<script>
import DraggableTreeview from "./OptionMenu/DraggableTreeview.vue";
export default {
  components: {
    DraggableTreeview,
  },
  props: {
    model: {
      type: Array,
      default: [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    OptionsOrder: [],
    ToAssingOption: [],
    ToAssingMenu: [],
    search: null,
  }),
  methods: {
    input(val) {
      let list = [];

      this.ToAssingMenu.concat(this.ToAssingOption).forEach((obj) => {
        list = list.concat(this.recursiveInverseOption(obj));
      });
      this.OptionsOrder.forEach((e) => {
        e.OptParent = null;
      });
      this.$nextTick(() => {
        //  this.model = this.OptionsOrder.concat(list);
        this.$emit("input", this.OptionsOrder.concat(list));
      });
    },
    inputMenu(val) {
      this.$nextTick(() => {
        var options = _.uniqBy(
          this.OptionsOrder.concat(this.ToAssingOption, this.ToAssingMenu),
          "OptID"
        );

        this.$emit("input", options);
      });
    },
    inputToAssignOption(val) {
      let list = [];

      this.ToAssingOption.forEach((obj) => {
        list = list.concat(this.recursiveInverseOption(obj));
      });

      this.ToAssingOption = list.filter(
        (x) => x.OptOrder == 0 && x.TypeOption == 2 && x.OptParent == null
      );
      this.ToAssingMenu = this.ToAssingMenu.filter(
        (x) => x.TypeOption == 1
      ).concat(
        list.filter(
          (x) => x.OptOrder == 0 && x.TypeOption == 1 && x.OptParent == null
        )
      );
    },
    recursiveInverseOption(opt) {
      let list = [];

      opt.OptOrder = 0;
      opt.OptParent = null;

      list.push(opt);

      opt.SecOptionChild.forEach((o) => {
        list = list.concat(this.recursiveInverseOption(o));
      });
      opt.SecOptionChild = [];

      return list;
    },
    editOption(value) {
      this.$emit("editOption", value);
    },
    removeOption(value) {
      this.$emit("removeOption", value);
    },
    saveMenu() {
      this.$emit("saveMenu");
    },
  },
  mounted() {},
  computed: {},
  watch: {
    model(val) {
      this.OptionsOrder = this.model.filter((x) => x.OptOrder != 0);
      this.ToAssingMenu = this.model.filter(
        (x) => x.OptOrder == 0 && x.TypeOption == 1 && x.OptParent == null
      );
      this.ToAssingOption = this.model.filter(
        (x) => x.OptOrder == 0 && x.TypeOption == 2 && x.OptParent == null
      );
    },
  },
};
</script>
