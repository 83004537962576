<template>
  <v-container class="px-0">
    <v-row>
      <v-col cols class="my-0 py-0">
        <v-row>
          <v-col cols="12" md="6">
            <option-detail @saveOption="saveOption" :optionedit="option" :loading="loadingdetail" />
          </v-col>
          <!-- 
      <v-col cols="3">
        <pre class="caption">  {{ JSON.stringify(optionList, null, 2) }}</pre>
      </v-col> -->

          <v-col cols="12" md="6">
            <option-menu
              :model="optionList"
              :loading="loadingmenu"
              @input="inputMenu"
              @editOption="editOption"
              @removeOption="removeOption"
              @saveMenu="saveMenu"
            />
          </v-col>
        </v-row>
        <v-treeview></v-treeview>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import _sOption from "@/services/Security/OptionService.js";
import OptionMenu from "./Option/OptionMenu.vue";
import OptionDetail from "./Option/OptionDetail.vue";

export default {
  components: { OptionMenu, OptionDetail },
  data: () => ({
    optionList: [],
    option: {},
    loadingdetail: false,
    loadingmenu: false,
  }),
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      _sOption.list(1, this.$fun.getUserID()).then((r) => {
        this.optionList = r.data;
      });
    },
    inputMenu(model) {
      this.optionList = model;
    },
    editOption(opt) {
      opt.Isload = false || opt.Isload;
      if (!opt.Isload) {
        this.loadingdetail = true;

        _sOption.get(opt.OptID, this.$fun.getUserID()).then((r) => {
          this.option = r.data;
          this.option.Isload = true;
          this.loadingdetail = false;
        });
      }

      this.option = opt;
    },
    removeOption(opt) {
      //No pude hacer splice(obj) no reconocio el objeto

      opt.SecStatus = 0;

      this.loadingmenu = true;
      _sOption.save(opt, this.$fun.getUserID()).then((r) => {
        var index = this.optionList.indexOf((x) => x.OptID == opt.OptID);
        this.optionList.splice(index);
        this.loadingmenu = false;
      });
    },
    saveOption(opt, isCreate) {
      if (isCreate) {
        this.optionList.push(opt);
      }
    },
    saveMenu() {
      this.loadingmenu = true;
      _sOption.savemenu(this.optionList, this.$fun.getUserID()).then((r) => {
        this.$store.getters.alertTop("Configuración de menú actualizada correctamente.", "success");
        this.loadingmenu = false;
      });
    },
  },
};
</script>
