<template>
  <div
    class="v-treeview-node v-treeview-node--click"
    :class="hasChildren && isTypeMenu ? '' : ' v-treeview-node--leaf'"
  >
    <div class="v-treeview-node__root">
      <div
        v-for="index in appendLevel"
        :key="index"
        class="v-treeview-node__level"
      />
      <i
        v-if="isDraggableMenu && isTypeMenu"
        role="button"
        class="v-icon notranslate v-treeview-node__toggle v-icon--link mr-3 mdi mdi-menu-down"
        :class="{
          'v-treeview-node__toggle--open': open,
          'theme--dark': isDark,
          'theme--light': !isDark,
        }"
        @click="open = !open"
      />
      <i
        v-if="isDraggableMenu && !isTypeMenu"
        class="v-treeview-node__toggle v-icon--link "
      ></i>

      <div class="v-treeview-node__prepend">
        <v-icon color="black" small right>{{ value.OptIcon }}</v-icon>
      </div>
      <div class="v-treeview-node__label">{{ value.OptName }}</div>
      <div class="v-treeview-node__prepend">
        <v-btn icon small text @click="edit(value)">
          <v-icon x-small>fas fa-pen</v-icon>
        </v-btn>
        <v-btn v-if="!isDraggableMenu" icon small text @click="remove(value)">
          <v-icon color="red darken-2" x-small>fas fa-minus-circle</v-icon>
        </v-btn>
      </div>
    </div>

    <div
      v-if="isDraggableMenu && open"
      class="v-treeview-node__children v-treeview-node__children__draggable"
    >
      <draggable
        v-if="isTypeMenu"
        :class="[!hasChildren ? 'treeview-draggable-no-child' : '']"
        :style="!hasChildren ? 'margin-left:' + 24 * (level + 2) + 'px' : ''"
        :move="onMove"
        ghost-class="ghost"
        :group="group"
        :name="name"
        :value="value.SecOptionChild"
        @input="updateValue"
      >
        <treeview-node
          v-if="isTypeMenu"
          v-for="child in value.SecOptionChild"
          :key="child.OptID"
          :name="name"
          :group="group"
          :parent="value"
          :value="child"
          :level="level + 1"
          @input="updateChildValue"
          v-on:editNode="edit"
          v-on:removeNode="remove"
        />
      </draggable>
    </div>
  </div>
</template>

<script>
import Draggable from "vuedraggable";

export default {
  name: "treeview-node",
  components: {
    Draggable,
  },
  props: {
    level: {
      type: Number,
      default: 0,
    },
    value: {
      type: Object,
      default: () => ({
        OptID: 0,
        OptName: "",
        SecOptionChild: [],
      }),
    },
    name: {
      type: String,
      default: "",
    },
    parent: {
      type: Object,
      default: () => ({ OptID: null }),
    },
    group: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      open: false,
      localValue: { ...this.value },
    };
  },
  computed: {
    hasChildren() {
      if (this.value.SecOptionChild === undefined) {
        this.value.SecOptionChild = [];
      }
      return (
        this.value.SecOptionChild != null &&
        this.value.SecOptionChild.length > 0
      );
    },
    isDark() {
      return this.$vuetify.theme.isDark;
    },
    appendLevel() {
      return this.isDraggableMenu
        ? this.level + (this.isTypeMenu || this.value.OptParent == null ? 0 : 1)
        : 0;
    },
    isTypeMenu() {
      return this.value.TypeOption == 1;
    },
    isDraggableMenu() {
      return this.name == "OptionsOrder";
    },
  },
  watch: {
    value(value) {
      this.localValue = { ...value };
    },
  },
  methods: {
    updateValue(value) {
      if (this.isDraggableMenu) {
        value.forEach((e, i) => {
          e.OptParent = this.localValue.OptID;
          e.OptOrder = i + 1;
        });
      } else {
        value.forEach((e) => {
          e.OptParent = null;
          e.OptOrder = 0;
        });
      }
      this.localValue.SecOptionChild = [...value];

      this.$emit("input", this.localValue);
    },
    updateChildValue(value) {
      if (this.localValue.SecOptionChild === undefined)
        this.localValue.SecOptionChild = [];

      const index = this.localValue.SecOptionChild.findIndex(
        (c) => c.OptID === value.OptID
      );

      this.$set(this.localValue.SecOptionChild, index, value);
      this.$emit("input", this.localValue);
    },
    onMove(e) {
      return (
        this.isDraggableMenu ||
        e.relatedContext.component.$attrs.name == "OptionsOrder"
      );
    },
    edit(value) {
      this.$emit("editNode", value);
    },
    remove(value) {
      this.$emit("removeNode", value);
    },
  },
};
</script>
<style scoped>
.treeview-draggable-no-child {
  min-height: 30px !important;
  border: 2px dashed #c1c1c1;
}
.v-treeview--dense .v-treeview-node__root {
  min-height: 20px;
}
</style>
